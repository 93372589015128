import React from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { AppButton, AppLogo, AppText, AppTitle } from "../../ui";
import { AppleStoreIcon, Stars } from "../../../content/assets";
import { navigate } from "gatsby-link";
import { fbq } from "../../../services/pixel";

const GetStartedFooter: React.FC = () => {
  const handleSubmit = () => {
    fbq("trackCustom", "start_questions");
    navigate("/onboarding/questions");
  };

  return (
    <FooterContainer>
      <FlexCol>
        <AppLogo size="medium" style={{ marginBottom: 24 }} />
        <AppTitle fontSize={36} maxWidth={650} textAlign="center">
          The world&apos;s most important knowledge, <Blue>visualized</Blue>.
        </AppTitle>
        <Ratings>
          <FlexRow>
            <img src={AppleStoreIcon} alt="The Apple Store Icon" />
            <span>7.4K Ratings</span>
          </FlexRow>
          <img src={Stars} alt="5 stars" />
        </Ratings>
      </FlexCol>
      <FlexCol>
        <DesktopText fontSize={24} style={{ marginBottom: "0.5rem" }}>
          <b>Free 7-Day Trial</b>
        </DesktopText>
        <DesktopText fontSize={16}>
          then <b>$99.99 per year</b> ($8.33/month)
        </DesktopText>
        <MobileText textAlign="center">7-day free trial, then</MobileText>
        <MobileText textAlign="center">
          <b>$99.99 per year</b> ($8.33/month)
        </MobileText>
        <AppButton margin="1rem 0 0 0" onClick={handleSubmit}>
          Get Started
        </AppButton>
      </FlexCol>
    </FooterContainer>
  );
};

const FooterContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 6rem;
  background: #fefefe;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;

  @media ${mobileSize} {
    margin-bottom: 1rem;
  }
`;

const Blue = styled.span`
  color: ${theme.PRIMARY_COLOR};
`;

const DesktopText = styled(AppText as any)`
  color: ${theme.PRIMARY_COLOR};
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileText = styled(AppText as any)`
  display: none;
  margin: 0;
  color: ${theme.PRIMARY_COLOR};
  @media ${mobileSize} {
    display: block;
  }
`;

const Ratings = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 9rem;
  font-size: 1.8rem;
  * {
    margin-right: 1rem;
  }

  @media ${mobileSize} {
    flex-direction: column;
    * :last-child {
      margin-right: 0;
    }
  }
`;

export default GetStartedFooter;
