import * as React from "react";
import { useEffect } from "react";
import { KaagLanding2 } from "../../components/PageSegments/onboarding/KaagLanding2";
import { kaagLandingViewed } from "../../services/mixpanel/mixpanel";
import { useOnboardingContext } from "../../components/PageSegments/onboarding/OnboardingContext";
import { BasicLayout } from "../../layouts/BasicLayout";

export default data => {
  const { search } = data.location;
  const { setOnboardingLandingPage } = useOnboardingContext();

  useEffect(() => {
    setOnboardingLandingPage("John Kaag Landing Page");
    kaagLandingViewed();
    if (search) {
      const urlParams = new URLSearchParams(search);
      // new cookie will be expired in 1 year
      const utm_date = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);

      for (const key of urlParams.keys()) {
        // set those parameter as cookies
        const value = urlParams.get(key);
        document.cookie = `${key}=${value}; expires=${utm_date.toUTCString()}`;
      }
    }
  }, [search, setOnboardingLandingPage]);

  return (
    <BasicLayout>
      <KaagLanding2 />
    </BasicLayout>
  );
};
