import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { navigate } from "gatsby-link";
import Img from "gatsby-image";
import { AppText, AppTitle, AppButton, AppLogo } from "../../../components/ui";
import { mobileSize } from "../../../util/variables";
import theme from "../../../util/theme";
import { fbq } from "../../../services/pixel";
import GetStartedFooter from "./GetStartedFooter";

export const KaagLanding2 = () => {
  const data = useStaticQuery(graphql`
    query KaagImagesQuery2 {
      keyArt: file(relativePath: { eq: "kaag2/JohnKaagKeyArt.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 380, height: 440 ) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
        mobile: childImageSharp {
          fluid(quality: 100, maxWidth: 250, maxHeight: 315 ) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      chapter1: file(relativePath: { eq: "kaag2/Nietzsche_ch1.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 180, height: 180 ) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
        mobile: childImageSharp {
          fluid(quality: 100, maxWidth: 168, maxHeight: 168 ) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      chapter2: file(relativePath: { eq: "kaag2/Nietzsche_ch2.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 180, height: 180 ) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
        mobile: childImageSharp {
          fluid(quality: 100, maxWidth: 168, maxHeight: 168 ) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      chapter3: file(relativePath: { eq: "kaag2/Nietzsche_ch3.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 180, height: 180 ) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
        mobile: childImageSharp {
          fluid(quality: 100, maxWidth: 168, maxHeight: 168 ) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      chapter4: file(relativePath: { eq: "kaag2/Nietzsche_ch4.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 180, height: 180 ) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
        mobile: childImageSharp {
          fluid(quality: 100, maxWidth: 168, maxHeight: 168 ) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const handleSubmit = () => {
    fbq("trackCustom", "start_questions");
    navigate("/onboarding/questions");
  };

  return (
    <Container>
      <KaagContainer>
        <LogoContainer>
          <AppLogo white size="medium"/>
        </LogoContainer>
        <AppTitle
          margin="0 0 1rem 0"
          maxWidth={500}
          textAlign="center"
          fontSize={36}
          color="#fefefe"
        >
          Philosophy and the Meaning of Life
        </AppTitle>
        <Subtitle>John Kaag</Subtitle>
        <ImgGradient>
          <DesktopDiv>
            <StyledImg noBorder fixed={data.keyArt.childImageSharp.fixed} alt="John Kaag smiling in front of purple background shape"/>
          </DesktopDiv>
          <MobileDiv>
            <StyledImg noBorder fluid={data.keyArt.mobile.fluid} alt="John Kaag smiling in front of purple background shape"/>
          </MobileDiv>
        </ImgGradient>
        <TopBorderContainer>
          <AppText color="#fefefe" textAlign="center">A weekly exploration into the history of philosophy, by professor and published author, John Kaag. New episodes every Tuesday.</AppText>
          <SignupContainer>
            <AppText
              fontSize={24}
              color="#000"
              textAlign="center"
              style={{ marginBottom: "0.5rem" }}
            >
              Sign up to start a <Blue><b>7-day free trial.</b></Blue>
            </AppText>
            <DesktopText fontSize={16} textAlign="center">
              then <b>$99.99 per year</b> ($8.33/month)
            </DesktopText>
            <MobileText fontSize={16} textAlign="center">
              You’ll get access to this visual guide & more!
            </MobileText>
            <DesktopButton onClick={handleSubmit}>Get Started</DesktopButton>
          </SignupContainer>
          <MobileText fontSize={16} color="#fefefe" textAlign="center">
            then <b>$99.99 per year</b> ($8.33/month)
          </MobileText>
          <MobileButton onClick={handleSubmit}>Get Started</MobileButton>
        </TopBorderContainer>
        <TopBorderContainer>
          <AppText
            color="#fefefe"
            textAlign="center"
            fontSize={24}
            mobileFontSize={24}
            style={{ margin: "3rem 0" }}
          >
            <b>See What&apos;s Inside</b>
          </AppText>
          <DesktopDiv>
            <ImageGrid>
              <StyledImg fixed={data.chapter1.childImageSharp.fixed} alt="person standing on mountain with flag in hand"/>
              <StyledImg fixed={data.chapter2.childImageSharp.fixed} alt="book with quote from Friedrich Nietzsche"/>
              <StyledImg fixed={data.chapter3.childImageSharp.fixed} alt="mountain with two roads going differing ways"/>
              <StyledImg fixed={data.chapter4.childImageSharp.fixed} alt="A man looking at another man through a blue-framed window"/>
            </ImageGrid>
          </DesktopDiv>
          <MobileDiv>
            <ImageGrid>
              <StyledImg fluid={data.chapter1.mobile.fluid} alt="person standing on mountain with flag in hand"/>
              <StyledImg fluid={data.chapter2.mobile.fluid} alt="book with quote from Friedrich Nietzsche"/>
              <StyledImg fluid={data.chapter3.mobile.fluid} alt="mountain with two roads going differing ways"/>
              <StyledImg fluid={data.chapter4.mobile.fluid} alt="A man looking at another man through a blue-framed window"/>
            </ImageGrid>
          </MobileDiv>
          <AppButton onClick={handleSubmit}>Get Started</AppButton>
        </TopBorderContainer>
      </KaagContainer>
      <GetStartedFooter/>
    </Container>
  );
};

const Container = styled.div`
  margin: 0;
  padding: 0;
  background-color: #292032;
`;

const KaagContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 5.6rem 0;
  max-width: 600px;
  background: #292032;
  color: #fefefe;
  
  & > * {
    margin-bottom: 2rem;
  }

  @media ${mobileSize} {
    max-width: 340px; 
    padding: 10rem 0;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 5.6rem;
  left: 12rem;
  height: 2.5rem;

  @media ${mobileSize} {
    position: static;
  }
`;

const Subtitle = styled.h2`
  margin: 0;
  margin-bottom: 2rem;

  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
`;

const ImgGradient = styled.div`
  position: relative;
  width: 380px;
  height: 440px;
  border-radius: 16px;

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: linear-gradient(
      to right,
      hsla(270, 22%, 16%, 0.75) 0%, 
      hsla(270, 22%, 16%, 0) 20%, 
      hsla(270, 22%, 16%, 0) 80%, 
      hsla(270, 22%, 16%, 0.75) 100%
    );
  }

  @media ${mobileSize} {
    width: 250px;
    height: 315px; 
  }
 `;

interface ImgProps {
  fixed?: File;
  fluid?: File;
  noBorder?: boolean;
}

const StyledImg = styled(Img)<ImgProps>`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: ${({ noBorder }) => noBorder ? "none" : "2px solid #fefefe"};
  object-fit: cover;
`;

const TopBorderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-top: 2px solid #EDEEEF;
  padding-top: 2rem; 
  color: #fefefe;
`;

const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  padding: 2rem 4rem;
  border-radius: 16px;
  width: 80%;
  background-color: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    width: 100%;
    padding: 2rem;
  }
`;

const DesktopDiv = styled.div`
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileDiv = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;

const DesktopText = styled(AppText as any)`
  color: ${theme.PRIMARY_COLOR};
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileText = styled(AppText as any)`
  display: none;
  margin: 0;
  color: ${theme.PRIMARY_COLOR};
  @media ${mobileSize} {
    display: block;
  }
`;

const DesktopButton = styled(AppButton as any)`
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileButton = styled(AppButton as any)`
  display: none;
  margin-top: 1rem;
  @media ${mobileSize} {
    display: block;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 180px);
  grid-template-rows: repeat(2, 180px);
  grid-gap: 24px;
  margin: 2rem 0 6rem 0;

  @media ${mobileSize} {
    grid-template-columns: repeat(2, 168px);
    grid-template-rows: repeat(2, 168px);
    grid-gap: 8px;
  }
`;

const Blue = styled.span`
  color: ${theme.PRIMARY_COLOR};
`;

